import classNames from 'classnames';
import { AssetPaths } from 'common/constants/assets';
import Button from 'components/elements/button';
import { AppFooter } from 'components/widgets/footer';
import PortalFooter from 'components/widgets/footer/portal';
import { isTabRoute } from 'hooks/use-kurosim-navigation';
import { ErrorView } from 'modules/status';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import Separator from '../separator';

interface OfflineViewComponentProps {
  refetch?(): void;
}

function OfflineViewRefetchButton(props: OfflineViewComponentProps) {
  const { refetch } = props;
  const { reload } = useRouter();
  const { t } = useTranslation();

  const { pathname } = useRouter();
  const isTab = React.useMemo(() => {
    return isTabRoute(pathname);
  }, [pathname]);

  const onClick = () => {
    if (refetch) {
      refetch();
    } else {
      reload();
    }
  };

  if (!isTab) {
    return (
      <PortalFooter>
        <AppFooter
          mainButtonProps={{
            type: 'main',
            onClick,
            children: t('common:retry'),
            fullWidth: true,
          }}
        />
      </PortalFooter>
    );
  }

  return (
    <>
      <Separator gap={16} direction="vertical" />
      <div
        className={classNames(
          structuralStyles.padding({ horizontal: 16 }),
          structuralStyles.fill({ width: true }),
        )}
      >
        <Button onClick={onClick} fullWidth>
          {t('common:retry')}
        </Button>
      </div>
    </>
  );
}

function OfflineViewComponent(props: OfflineViewComponentProps) {
  const { t } = useTranslation();
  return (
    <ErrorView
      title={t('error:connection_error')}
      description={t('error:connection_error_description')}
      below={<OfflineViewRefetchButton {...props} />}
      imageSource={AssetPaths.ConnectionError}
    />
  );
}

export default OfflineViewComponent;
