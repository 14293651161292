import classNames from 'classnames';
import { NavigationRoutes } from 'common/routes';
import Button from 'components/elements/button';
import Text from 'components/elements/text';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import { UnauthorizeView } from 'modules/common/unauthorize-view';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import Separator from './separator';

function Container({ children }) {
  return (
    <div
      className={classNames(
        structuralStyles.flexbox({
          direction: 'column',
          justify: 'center',
        }),
        structuralStyles.fill({
          height: true,
          width: true,
        }),
      )}
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {children}
    </div>
  );
}

function DefaultUnauthorizedView() {
  const { t } = useTranslation();
  const { push } = useKurosimNavigation();

  return (
    <Container>
      <Text textVariant="body1Semibold">{t('error:need_login')}</Text>
      <Separator gap={16} />
      <Button onClick={() => push(NavigationRoutes.Login)}>
        {`${t('common:login')} / ${t('common:sign_up')}`}
      </Button>
    </Container>
  );
}

export interface LoginViewComponentProps {}

export default function LoginViewComponent(props: LoginViewComponentProps) {
  const { currentRoute } = useKurosimNavigation();
  const route = currentRoute().pathname;

  switch (route) {
    case NavigationRoutes.Orders:
      return (
        <Container>
          <UnauthorizeView type="order" />
        </Container>
      );
    case NavigationRoutes.MySim:
      return (
        <Container>
          <UnauthorizeView type="card" />
        </Container>
      );
    case NavigationRoutes.Referral:
      return (
        <Container>
          <UnauthorizeView type="referral" />
        </Container>
      );
    default:
      return <DefaultUnauthorizedView />;
  }
}
